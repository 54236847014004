import { FC } from 'react';
import { Box, Footer, Group, Text } from '@mantine/core';
import { appSections } from '@/constants/appSections';
import Section from '@/ui/Section/Section';
import Telegram from '@/assets/inline-svg/Telegram.svg';
import Metro from '@/assets/inline-svg/Metro.svg';
import Walker from '@/assets/images/walker.png';

import { useStyles } from './styles';

const LayoutFooter: FC = () => {
  const { classes, cx } = useStyles();

  return (
    <Footer className={classes.root} withBorder={false} height={473}>
      <Section id={appSections.contacts.id} bg="#FFF8F8">
        <Section.Title mb={{ md: 60 }}>Контакты</Section.Title>

        <Group className={classes.row}>
          <Box className={classes.contacts}>
            {/* <a
              className={cx(classes.contact, classes.contactHighlighted)}
              href="https://t.me/agilehub_ru"
            >
              <Telegram />
              agilehub_ru
            </a> */}
            <a className={classes.contact} href="tel:+74994905038">
              +7 499 490 50 38
            </a>
            <a className={classes.contact} href="mailto:info@agilehub.ru">
              info@agilehub.ru
            </a>
          </Box>

          <Box className={classes.address}>
            <Text className={classes.addressTitle}>
              Россия, Москва,
              <br /> дизайн-завод Флакон
            </Text>
            <Group className={classes.addressGroup}>
              <Box className={classes.addressInfo}>
                <Metro /> Дмитровская
              </Box>
              <Box className={classes.addressInfo}>
                <img src={Walker} alt="" width={18} height={22} /> 5 минут
              </Box>
            </Group>
            <Text className={classes.addressText}>
              ул. Большая Новодмитровская, д. 36, стр. 12, вход 6
            </Text>
          </Box>

          <Box className={classes.address}>
            <Text className={classes.addressTitle}>
              Canada, Toronto
              <br /> Toronto ON M4W 3Z4
            </Text>
            <Group className={classes.addressGroup}>
              <Box className={classes.addressInfo}>
                <Metro /> Bloor-Yonge
              </Box>
              <Box className={classes.addressInfo}>
                <img src={Walker} alt="" width={18} height={22} /> 3 минуты
              </Box>
            </Group>
            <Text className={classes.addressText}>
              825 Church street
              <br /> unit 2909
            </Text>
          </Box>
        </Group>

        <Box className={classes.copy}>© 2025 ООО «Проекты» ИНН 7606133386</Box>
      </Section>
    </Footer>
  );
};

export default LayoutFooter;
