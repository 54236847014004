import { FC, useState } from 'react';
import Cookies from 'universal-cookie';
import { Dialog } from '@mantine/core';

import { useStyles } from './styles';

const CookieDialog: FC = () => {
  const { classes } = useStyles();

  const cookies = new Cookies();
  const [opened, setOpened] = useState(true);

  const closeDialog = () => {
    cookies.set('cookie_message', '1', {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 30),
    });
    setOpened(false);
  };

  return !cookies.get('cookie_message') ? (
    <Dialog
      classNames={{ root: classes.root, closeButton: classes.closeButton }}
      opened={opened}
      onClose={() => closeDialog()}
      withCloseButton
      position={{ bottom: 20, right: 20 }}
    >
      Мы используем файлы cookie, это улучшает работу сайта и предоставляет вам больше возможностей.
      Продолжая использовать сайт, вы{' '}
      <a href="/privacy-policy/" target="_blank">
        соглашаетесь с условиями
      </a>{' '}
      использования cookie.
    </Dialog>
  ) : (
    <></>
  );
};

export default CookieDialog;
