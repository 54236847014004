import { FC, ReactNode } from 'react';
import { AppShell } from '@mantine/core';

import LayoutHeader from './LayoutHeader/LayoutHeader';
import LayoutFooter from './LayoutFooter/LayoutFooter';
import CookieDialog from '../CookieDialog/CookieDialog';

import { useStyles } from './styles';

interface ILayout {
  children: ReactNode;
}

const Layout: FC<ILayout> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <>
      <AppShell
        fixed={false}
        padding={0}
        classNames={{
          root: classes.root,
          body: classes.body,
        }}
        header={<LayoutHeader />}
        footer={<LayoutFooter />}
      >
        {children}
      </AppShell>

      <CookieDialog />
    </>
  );
};

export default Layout;
