import { createStyles } from '@mantine/core';
import { COLORS } from '@/constants/mantine/colors';

export const useStyles = createStyles((theme) => ({
  root: {
    fontSize: 16,
    lineHeight: 1.64,
    borderColor: COLORS.GRAY.border,
    borderRadius: 10,
    width: 400,
    maxWidth: 'calc(100vw - 40px)',

    [`@media (max-width: ${theme.breakpoints.sm - 1}px)`]: {
      padding: 20,
    },
    [`@media (max-width: ${theme.breakpoints.xs - 1}px)`]: {
      padding: '20px 16px',
    },

    a: {
      color: '#000',
    },
  },

  closeButton: {
    borderRadius: 7,
    top: 2,
    right: 2,

    svg: {
      width: 20,
      height: 20,
    },
  },
}));
